import React from 'react';

export const page1 = [
  {
    title: 'TITOLO 1',
    content: '支持可视化编辑、自由拖拽排版、实时预览、在线访问加速等功能',
    src: 'https://gw.alipayobjects.com/zos/rmsportal/MmROsqZndrFBrIspzLlL.png',
  },
  {
    title: 'TITOLO 2',
    content: '网站内设有数据监测埋点，助你时刻掌握站点效果，鉴别有效渠道',
    src: 'https://gw.alipayobjects.com/zos/rmsportal/ONvKhpRAmkCfdEhkUZkJ.png',
  },
  {
    title: 'TITOLO 3',
    content: '企业版支持以团队管理的方式，多人协作完成营销站点，共享资源',
    src: 'https://gw.alipayobjects.com/zos/rmsportal/oxmXLgGjCeXfYPcVSbKg.png',
  },
];

export const footer = [
  { text: 'FM-Base.co.uk', src: 'https://fm-base.co.uk' },
  { text: 'FM-Base announcement', src: 'https://fm-base.co.uk/articles/merger-announcement-mr-l-tactics-and-fm-base.110/' },
  { text: 'Discord Server', src: 'https://discord.gg/p2ZMrCq' },
];
