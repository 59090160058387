import React from 'react';
import logo from "../imgs/logo.png"

export default function Header(props) {
  return (
    <header {...props}>
      <div className="header-content">
        <h1>
          <span><img src={logo} style={{ width: 120, height: 31 }} alt={"MrLTactics"} /></span>
        </h1>
      </div>
    </header>
  );
}
