import React from 'react';
import TweenOne from 'rc-tween-one';
import SvgDrawPlugin from 'rc-tween-one/lib/plugin/SvgDrawPlugin';

TweenOne.plugins.push(SvgDrawPlugin);

export default function BannerImage() {
  return (
    <img style={{width:"100%"}} src="https://fm-base.co.uk/articles/merger-announcement-mr-l-tactics-and-fm-base.110/cover-image" />);
}
