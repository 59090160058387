import React from "react"
import QueueAnim from "rc-queue-anim"
import OverPack from "rc-scroll-anim/lib/ScrollOverPack"
import { Row, Col } from "antd"
import { page1 } from "./data"
import { Typography, Divider } from 'antd';

const { Title, Paragraph, Text } = Typography;

export default function Page1() {

  return (
    <div className="home-layout-wrapper home-func-wrapper" id="home-func">
      <h2>Announcement from MrLTactics</h2>
      <i className="line" />
            <div className="home-layout">
              <p>
                We are thrilled to announce that Mr L Tactics and FM Base are now operating as one. Base has been the tactical
                hub for the Football Manager community since its conception and the actual original place where the Mr L Tactics
                project was born.
              </p>
              <p>
                We both have an incredible passion for the tactical side of Football Manager and believe that by working
                together we can achieve greater goals and provide more to the Football Manager community.
              </p>
              <p>
                The primary purpose of our merger is to rebuild FM Base from the ground up. It will be a phased rollout,
                however, from release of FM 21, you can expert the first new features. It will be very much a case of out with
                the old and in with the new.
              </p>
              <p>
                Our aim is to modernise the user experience in order to bring forward a state-of-the-art experience, which is
                innovative and unprecedented. The idea of changing the user experience is born from the will to unleash the full
                potential of discussion within our community, highlighting the phenomenal quality of thought and work done by
                the members of our community. Meanwhile, we want to build upon and emphasise the spirit of cooperation which has
                always been a pillar of FM Base and Mr L Tactics.
              </p>
              <p>
                We are also pleased to announce that Tactic Testing will be making a strong return with FM21 and will be our
                most prominent feature. The merger with with FM Base will provide the resources needed to finalize the automated
                tactic testing process with a formula that has constantly been developed and improved over the past eight years:
                a true product of love.
              </p>
              <p>
                We are really excited for you to join us on the next stage of our journey together.
              </p>
              <p>
                We are thrilled to announce that Mr L Tactics and FM Base are now operating as one. Base has been the tactical
                hub for the Football Manager community since its conception and the actual original place where the Mr L Tactics
                project was born.
              </p>
              <p>
                The best is yet to come.
              </p>
            </div>
    </div>)
}
